import './App.css';
import React from 'react';
import ScratchCard from './ScratchCard'; 

const App = () => {
  const [minutes, setMinutes] = React.useState(4);
  const [seconds, setSeconds] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [minutes, seconds]);

  return (
    <div className="App">
      <header className="App-header">
      <h2>Essa oferta irá encerrar em: {minutes}:{seconds.toString().padStart(2, '0')}</h2>
      </header>
      <div className='container'>
        <img style={{ maxWidth: 400, width: "80%" }} src='texto.png' alt='Texto Promocional' />
        <div className='moldura_top'>
          <h2>ENCONTRE 3 BILHETES PARA GANHAR</h2>
        </div>
        <div className='moldura'>
          <ScratchCard imageSrc="/raspadinha.png" specificSrc={"/gift.png"} />
        </div> 
        <div className='moldura_bottom'>
              <div className='call_to_action pulse'> PASSE O DEDO PARA RASPAR </div>     
        </div>
      </div>
    </div>
  );
};

export default App;
