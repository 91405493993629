import { useEffect, useState } from "react";
import "./modal.css";

export default function Modal() {
  const [delayState, setDelayState] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayState(true);
    }, 4000);

    return () => clearTimeout(timer); // Limpa o timeout ao desmontar
  }, []);

  return (
    <div style={{ display: delayState ? "flex" : 'none' }} className="modal-container">
      <div className="modal_box">
        <img className="parabens" src="./parabens.png" alt="Parabéns" />
        <h2>Você ganhou a oportunidade de aumentar o seu depósito em 100%! <br /> Escolha a melhor opção para você e se divirta!</h2>
        <a href="https://primeodds.co/btagraspadinha1030">
          <h3 className="rodadasGratis"> DEPÓSITO DE R$10,00 | <span className="boldDobra"> DOBRAMOS PARA R$20,00 </span> </h3>
        </a>
        <a href="https://primeodds.co/btagraspadinha3070">
          <h3 className="rodadasGratis"> DEPÓSITO DE R$30,00 | <span className="boldDobra"> DOBRAMOS PARA R$60,00 </span> </h3>
        </a>
        <a href="https://primeodds.co/btagraspadinha50100">
          <h3 className="rodadasGratis"> DEPÓSITO DE R$50,00 | <span className="boldDobra"> DOBRAMOS PARA R$100,00 </span> </h3>
        </a>
        <img className="logoPrime" src="./primeoddslogo.png" alt="logoPrimeOdds" />
      </div>
    </div>
  );
}
