import React, { useRef, useState, useEffect } from 'react';
import './ScratchCard.css';
import Modal from './Modal';

const ScratchCard = ({ imageSrc, specificSrc }) => {
  const overlayRef = useRef(null);
  const [isComplete, setIsComplete] = useState(false);
  const [lastPosition, setLastPosition] = useState({ x: null, y: null });
  const [hasInteracted, setHasInteracted] = useState(false);

  const cellImages = ["/gift.png", "/5.png", "/gift.png", "/2.png", "/gift.png", "/5.png", "/2.png", "/20.png", "/20.png"];



  useEffect(() => {
    if (isComplete) {
      document.body.classList.add('no-scroll');
    } 
  }, [isComplete]);


  const handleMouseMove = (event) => {
    if (!hasInteracted) {
      setHasInteracted(true);
      document.body.classList.add('no-scroll');
    }

    const canvas = overlayRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (event.buttons !== 1) return;

    draw(ctx, x, y);
    checkIfComplete();
  };

  const handleTouchMove = (event) => {
    if (!hasInteracted && window.innerWidth <= 480) {
      setHasInteracted(true);
      document.body.classList.add('no-scroll');
    }

    event.preventDefault();
    const canvas = overlayRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = event.touches[0].clientX - rect.left;
    const y = event.touches[0].clientY - rect.top;

    draw(ctx, x, y);
    checkIfComplete();
  };

  const draw = (ctx, x, y) => {
    ctx.globalCompositeOperation = 'destination-out';
    ctx.lineWidth = 40;
    ctx.lineCap = 'round';

    if (lastPosition.x !== null && lastPosition.y !== null) {
      ctx.beginPath();
      ctx.moveTo(lastPosition.x, lastPosition.y);
      ctx.lineTo(x, y);
      ctx.stroke();
    }

    setLastPosition({ x, y });
  };

  const checkIfComplete = () => {
    const canvas = overlayRef.current;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const totalPixels = data.length / 4;
    let scratchedPixels = 0;

    for (let i = 0; i < data.length; i += 4) {
      if (data[i + 3] === 0) {
        scratchedPixels++;
      }
    }

    if (scratchedPixels / totalPixels > 0.85) {
      setIsComplete(true);
    }
  };

  const drawOverlay = () => {
    const overlayCanvas = overlayRef.current;
    const ctx = overlayCanvas.getContext('2d');
    const image = new Image();

    image.onload = () => {
      overlayCanvas.width = image.width;
      overlayCanvas.height = image.height;
      ctx.drawImage(image, 0, 0, overlayCanvas.width, overlayCanvas.height);
    };

    image.src = imageSrc;
  };

  const handleMouseUpOrLeave = () => {
    setHasInteracted(false);
    setLastPosition({ x: null, y: null });
  };

  useEffect(() => {
    drawOverlay();
  }, [imageSrc]);

  return (
    <div className='divGeral'>
      <div className="grid">
        <canvas
          ref={overlayRef}
          className='canva_inset'
          style={{ position: 'absolute', zIndex: 2, pointerEvents: isComplete ? "none" : '' }}
          onMouseMove={handleMouseMove}
          onTouchMove={handleTouchMove}
          onMouseUp={handleMouseUpOrLeave}
          onMouseLeave={handleMouseUpOrLeave}
          onTouchEnd={handleMouseUpOrLeave}
        />
        {cellImages.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`cell-${index}`}
            className={isComplete && src === specificSrc ? 'pulse' : ''}
            style={{ objectFit: 'contain', width: "100%", height: '90px' }}
          />
        ))}
      </div>
      {isComplete && <Modal />}
    </div>
  );
};

export default ScratchCard;
